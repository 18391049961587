import { FC } from 'react';
import { DomElement } from 'domhandler';
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';

import { useSelector } from 'src/hooks/useSelector';

import ArticleAlbum from 'src/pages/Article/components/Content/BlogArticle/mappers/ArticleAlbum';
import ArticleLink from 'src/pages/Article/components/Content/BlogArticle/mappers/ArticleLink';
import Cut from 'src/pages/Article/components/Content/BlogArticle/mappers/Cut';

import styles from './style.less';

interface Props {
    landingDesign: boolean;
}

const ParsedContent: FC<Props> = ({ landingDesign }) => {
    const content = useSelector((state) => state.articlePage.content);

    const options: HTMLReactParserOptions = {
        replace: (originalElement: DomElement) => {
            const { attribs, name, children } = originalElement;
            if (attribs?.['data-cut']) {
                return <Cut options={options} originalElement={originalElement} landingDesign={landingDesign} />;
            }
            if (name === 'nofollow' || name === 'noindex') {
                return children ? <>{domToReact(children, options)}</> : <></>;
            }
            if (name === 'a') {
                return <ArticleLink options={options} originalElement={originalElement} />;
            }
            if (attribs?.class?.split(' ').includes('cms-album')) {
                return <ArticleAlbum options={options} originalElement={originalElement} />;
            }
            return null;
        },
    };

    if (!content) {
        return null;
    }

    return <div className={styles.cmsContent}>{parse(content, options)}</div>;
};

export default ParsedContent;
