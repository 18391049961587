import { HSpacing, VSpacing } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { H2 } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import ArticleAnnounce from 'src/pages/Article/components/ContentHeader/common/IntroductionParagraph/ArticleAnnounce';
import ArticleLead from 'src/pages/Article/components/ContentHeader/common/IntroductionParagraph/ArticleLead';
import useIsIntroductionParagraph from 'src/pages/Article/components/ContentHeader/common/IntroductionParagraph/useIsIntroductionParagraph';
import LandingTextContainer from 'src/pages/Article/components/common/LandingTextContainer';
import useIsInsider from 'src/pages/Article/hooks/useIsInsider';

import InsiderParticipant from 'src/pages/Article/components/ContentHeader/LandingContentHeader/InsiderParticipant';

import styles from './landing-content-header.less';

const TrlKeys = {
    companyVacancies: 'article.insiderInterview.vacancies',
    otherInterviews: 'article.insiderInterview.otherInterviews',
};

const LandingContentHeader: TranslatedComponent = ({ trls }) => {
    const info = useSelector((state) => state.articleEmployerInfo);
    const otherInterviews = useSelector((state) => state.insiderArticleSpecificData?.otherInterviews);
    const participants = useSelector((state) => state.insiderArticleSpecificData?.participants);
    const isInsider = useIsInsider();
    const isIntroductionParagraph = useIsIntroductionParagraph();

    const isEmployerInfo = !!info;
    const isLinks = isEmployerInfo && isInsider;
    const isOtherInterviewsLink = isLinks && otherInterviews && otherInterviews.length > 0;
    const isParticipants = isEmployerInfo && isInsider && !!participants;
    const isSpacingBeforeIntroductionParagraph = isEmployerInfo && isIntroductionParagraph;

    return (
        <>
            {isEmployerInfo && (
                <LandingTextContainer>
                    {info.logo && <img loading="lazy" src={info.logo} className={styles.logo} alt={info.name} />}
                    <div className={styles.container}>
                        <H2>{info.name}</H2>
                        {info.siteUrl && <SPALink to={info.siteUrl}>{info.siteUrl}</SPALink>}
                        {!!info.industries.length && (
                            <>
                                <VSpacing default={12} />
                                {info.industries.map((industry) => (
                                    <p key={industry}>{industry}</p>
                                ))}
                            </>
                        )}
                    </div>
                </LandingTextContainer>
            )}
            {isLinks && (
                <>
                    <VSpacing default={12} />
                    <LandingTextContainer>
                        <div className={styles.container}>
                            <div className={styles.linksContainer}>
                                <BlokoLink Element={SPALink} to={`/employer/${info.id}`} disableVisited>
                                    {trls[TrlKeys.companyVacancies]}
                                </BlokoLink>
                                {isOtherInterviewsLink && (
                                    <div>
                                        <BlokoLink href="#interview" disableVisited>
                                            {trls[TrlKeys.otherInterviews]}
                                        </BlokoLink>
                                        <HSpacing default={6} />
                                        <span className={styles.interviewCount}>{otherInterviews.length}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </LandingTextContainer>
                </>
            )}
            {isParticipants && (
                <>
                    <VSpacing default={24} />
                    <div className={styles.participants}>
                        {participants?.map((participant, index) => (
                            <LandingTextContainer key={participant.id}>
                                {index > 0 && <VSpacing default={20} />}
                                <InsiderParticipant participant={participant} />
                            </LandingTextContainer>
                        ))}
                    </div>
                </>
            )}
            {isSpacingBeforeIntroductionParagraph && <VSpacing default={isInsider ? 44 : 24} />}
            {isIntroductionParagraph && (
                <LandingTextContainer>{isInsider ? <ArticleLead /> : <ArticleAnnounce />}</LandingTextContainer>
            )}
        </>
    );
};

export default translation(LandingContentHeader);
