import { FC, memo } from 'react';
import classnames from 'classnames';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import HSpacing from 'bloko/blocks/hSpacing';
import { H2Promo } from 'bloko/blocks/header';

import ArticleEventDates from 'src/components/ArticleEventDates';
import ArticleViewsCount from 'src/components/ArticleViewsCount';
import HumanDate from 'src/components/HumanDate';
import { useSelector } from 'src/hooks/useSelector';
import { ArticleTypes } from 'src/models/articlePage/types';
import usePageHeader from 'src/pages/Article/components/PageHeader/usePageHeader';
import Breadcrumbs from 'src/pages/Article/components/common/Breadcrumbs';

import ShareBar from 'src/pages/Article/components/PageHeader/DefaultPageHeader/ShareBar';

const PageHeaderDefault: FC = () => {
    const { title, publicationTime, image } = usePageHeader();
    const breadcrumbsMarks = useSelector((state) => state.articlePage?.breadcrumbs);
    const articleViews = useSelector((state) => state.articleViews);
    const showShareButtons = useSelector((state) => state.articlePage?.showShareButtons);
    const showMainArticleImage = useSelector((state) => state.articlePage?.showMainArticleImage);
    const type = useSelector((state) => state.articlePage?.type);

    const eventStartDate = useSelector((state) => state.articlePage?.eventStartDate);
    const eventStartDateShort = useSelector((state) => state.articlePage?.eventStartDateShort);
    const eventEndDate = useSelector((state) => state.articlePage?.eventEndDate);

    const getBackgroundImage = () => {
        if (!showMainArticleImage || !image) {
            return undefined;
        }
        return { backgroundImage: `url(${image.url})` };
    };

    return (
        <div
            className={classnames('cms-header', {
                'cms-header_alt': showMainArticleImage,
            })}
            style={getBackgroundImage()}
        >
            <ColumnsWrapper>
                <Column xs="4" s="8" m="12" l="16">
                    <div
                        className={classnames('cms-header-content', {
                            'cms-header-content_alt': !showMainArticleImage,
                        })}
                    >
                        <div className="cms-header-content__wrapper">
                            <div className="cms-header-content__top">
                                {!!breadcrumbsMarks?.length && <Breadcrumbs breadcrumbsMarks={breadcrumbsMarks} />}
                                {title && (
                                    <div className="cms-header-content__title">
                                        <H2Promo Element="h1">{title}</H2Promo>
                                    </div>
                                )}
                                <div className="cms-header-content__info">
                                    <div className="cms-header-content__data">
                                        {type === ArticleTypes.Event && (
                                            <span className="cms-header-content__date">
                                                <ArticleEventDates
                                                    eventStartDate={eventStartDate}
                                                    eventStartDateShort={eventStartDateShort}
                                                    eventEndDate={eventEndDate}
                                                />
                                            </span>
                                        )}
                                        {type !== ArticleTypes.Event && publicationTime && (
                                            <span className="cms-header-content__date">
                                                <HumanDate date={publicationTime} />
                                            </span>
                                        )}
                                        {articleViews?.isEnabled && articleViews?.count && (
                                            <>
                                                <HSpacing base={2} />
                                                •
                                                <HSpacing base={2} />
                                                <div className="cms-header-content__views_count">
                                                    <ArticleViewsCount
                                                        count={articleViews.count}
                                                        iconInitialColor="contrast"
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {showShareButtons && (
                                <div className="cms-header-content__bottom">
                                    <ShareBar />
                                </div>
                            )}
                        </div>
                    </div>
                </Column>
            </ColumnsWrapper>
        </div>
    );
};

export default memo(PageHeaderDefault);
