import { FC } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';

import Compensation from 'src/components/Compensation';
import translation from 'src/components/translation';
import { VacancySummaryType } from 'src/models/vacancySummary/types';

import { getVacancyLink } from 'src/pages/Article/components/Sidebar/DefaultSidebar/VacancySummary/utils';
import { VacancyLinkQueryParams } from 'src/pages/Article/components/Sidebar/DefaultSidebar/VacancySummary/utils/getVacancyLink';

import styles from './vacancy-summary.less';

export interface VacancySummaryLinkProps {
    vacancy: VacancySummaryType;
    vacancyLinkQueryParams: VacancyLinkQueryParams;
    onClick?: () => void;
    setVacancyRef?: (ref: HTMLElement | null) => void;
}

const VacancySummaryLink: FC<VacancySummaryLinkProps> = ({
    vacancy,
    vacancyLinkQueryParams,
    onClick,
    setVacancyRef,
}) => {
    return (
        <BlokoLink
            kind={LinkKind.Tertiary}
            Element={SPALink}
            to={getVacancyLink(vacancy)}
            additionalQueryParams={vacancyLinkQueryParams}
            target="_blank"
            onClick={onClick}
        >
            <span ref={setVacancyRef} className={styles.title} data-qa="vacancy-summary-title">
                {vacancy.name}
            </span>
            <span className={styles.compensation} data-qa="vacancy-summary-compensation">
                <Compensation
                    {...vacancy.compensation}
                    analyticsContext="ArticleVacancySummaryLink"
                    showNoSalaryMessage
                />
            </span>
        </BlokoLink>
    );
};

export default translation(VacancySummaryLink);
