import { FC } from 'react';

interface ArticleAlbumControlsProps {
    handleClick?: (index: number) => void;
    itemsCount?: number;
    activeItemIndex?: number;
}

const ArticleAlbumControls: FC<ArticleAlbumControlsProps> = ({ handleClick, itemsCount, activeItemIndex }) => {
    const prevClick =
        activeItemIndex === undefined || handleClick === undefined
            ? undefined
            : () => {
                  handleClick(activeItemIndex - 1);
              };
    const nextClick =
        activeItemIndex === undefined || handleClick === undefined
            ? undefined
            : () => {
                  handleClick(activeItemIndex + 1);
              };
    const activeItemCaption = activeItemIndex === undefined ? undefined : activeItemIndex + 1;
    return (
        <div className="cms-album__toolbar cms-album__frame">
            <div className="cms-album__control">
                <span className="cms-album__arrow cms-album__arrow_prev" onClick={prevClick} />
                <span className="cms-album__counter">
                    <span className="cms-album__counter-current">{activeItemCaption}</span>
                    {'/'}
                    <span className="cms-album__counter-total">{itemsCount}</span>
                </span>
                <span className="cms-album__arrow cms-album__arrow_next" onClick={nextClick} />
            </div>
        </div>
    );
};

export default ArticleAlbumControls;
