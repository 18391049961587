import { domToReact } from 'html-react-parser';

import { H1 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import { TagMapperComponent, TagMapperProps } from 'src/hooks/useParseHtml';

const HHAPageHeader: TagMapperComponent<TagMapperProps> = ({ options, originalElement: { children } }) => {
    if (!children) {
        return null;
    }
    return (
        <>
            <H1>{domToReact(children, options)}</H1>
            <VSpacing base={4} />
        </>
    );
};

export default HHAPageHeader;
