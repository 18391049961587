import { FC, useCallback, useRef, useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import Link, { LinkAppearance } from 'bloko/blocks/link';

import NoIndex from 'src/components/NoIndex';
import GeoSwitcherPopup from 'src/components/SupernovaMainMenu/GeoSwitcherPopup';
import { OverlayTypes } from 'src/components/SupernovaOverlayMenu/Overlay';
import RegionClarificationTooltip from 'src/components/SupernovaOverlayMenu/RegionClarificationTooltip';
import useOverlayMenu from 'src/components/SupernovaOverlayMenu/useOverlayMenu';
import useHandleOpenGeoSwitcher from 'src/hooks/useHandleOpenGeoSwitcher';
import { useSelector } from 'src/hooks/useSelector';

const BLOKO_COLOR_GREY_80 = '#303233';

const AreaSwitcherPromo: FC = () => {
    const domainAreaTitle = useSelector(({ domainAreaTitle }) => domainAreaTitle);
    const bodyColorStyle = useRef('');
    const ref = useRef(null);

    useEffect(() => {
        bodyColorStyle.current = document.body.style.color;
    }, []);

    const { showOverlay } = useOverlayMenu();

    const handleShowGeoSwitcherOverlay = () => {
        showOverlay(OverlayTypes.AreaSwitcherOverlay);
    };

    const openGeoSwitcher = useHandleOpenGeoSwitcher(handleShowGeoSwitcherOverlay);

    const handleClick = useCallback(() => {
        document.body.style.color = BLOKO_COLOR_GREY_80;
        openGeoSwitcher();

        Analytics.sendHHEventButtonClick('vacancy_search_region');
    }, [openGeoSwitcher]);

    return (
        <>
            <NoIndex>
                <Link
                    data-qa="mainmenu_areaSwitcher"
                    appearance={LinkAppearance.Pseudo}
                    title={domainAreaTitle}
                    onClick={handleClick}
                    ref={ref}
                >
                    <span className="supernova-navi-item_area-switcher-button">{domainAreaTitle}</span>
                </Link>
            </NoIndex>

            <RegionClarificationTooltip showOverlay={showOverlay} activatorRef={ref} />
            <GeoSwitcherPopup placement="bottom-left" activatorRef={ref} />
        </>
    );
};

export default AreaSwitcherPromo;
