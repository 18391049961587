import { TagMapperComponent, TagMapperProps } from 'src/hooks/useParseHtml';
import { useSelector } from 'src/hooks/useSelector';

const HHADomain: TagMapperComponent<TagMapperProps> = () => {
    const { domain } = useSelector((state) => state.articleHHATags.additionalData);

    return domain ? <>{domain}</> : null;
};

export default HHADomain;
