import { FC } from 'react';

import Portal from 'src/components/CodeInjector/Portal';
import useParseHtml, { createTagMapper } from 'src/hooks/useParseHtml';
import { HHAPortalData, HHATag } from 'src/models/articleHHATags';

import HHADomain from 'src/pages/Article/components/Content/PromoArticle/HHAMappers/HHADomain';
import HHAPageHeader from 'src/pages/Article/components/Content/PromoArticle/HHAMappers/HHAPageHeader';

const HHAPortal: FC<HHAPortalData> = ({ placeName, tagXmlStr }) => {
    const parseHtml = useParseHtml({
        [HHATag.PageHeader]: createTagMapper(HHAPageHeader, {}),
        [HHATag.Domain]: createTagMapper(HHADomain, {}),
    });

    if (!tagXmlStr) {
        return null;
    }

    return <Portal place={placeName}>{parseHtml(tagXmlStr)}</Portal>;
};

export default HHAPortal;
