import { ButtonKind } from 'bloko/blocks/button';

import { TagMapperComponent, TagMapperProps } from 'src/hooks/useParseHtml';
import LinkMapper from 'src/pages/Article/components/common/LinkMapper';

import ArticleButtonLink from 'src/pages/Article/components/Content/BlogArticle/mappers/ArticleLink/ArticleButtonLink';

import styles from './style.less';

const ArticleLink: TagMapperComponent<TagMapperProps> = ({ options, originalElement }) => {
    const { attribs, parent } = originalElement;
    if (attribs?.class.includes('cms-link')) {
        return <LinkMapper options={options} originalElement={originalElement} />;
    }
    if (attribs?.class.includes('cms-button')) {
        return (
            <div className={styles.buttonWrapper}>
                <ArticleButtonLink options={options} originalElement={originalElement} kind={ButtonKind.Primary} />
            </div>
        );
    }
    if (
        parent?.attribs?.class?.includes('cms-banner__button') ||
        parent?.parent?.attribs?.class?.includes('cms-banner__button')
    ) {
        return <ArticleButtonLink options={options} originalElement={originalElement} />;
    }
    return null;
};

export default ArticleLink;
