import { FC } from 'react';

import ContainerForXSL from 'src/components/CodeInjector/ContainerForXSL';
import Portal from 'src/components/CodeInjector/Portal';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';

import AreaSwitcherPromo from 'src/pages/Article/components/Content/PromoArticle/AreaSwitcherPromo';
import HHAPortal from 'src/pages/Article/components/Content/PromoArticle/HHAPortal';

const PromoArticle: FC = () => {
    const showArticleAreaSwitcher = useSelector((state) => state.showArticleAreaSwitcher);
    const hhaPortals = useSelector((state) => state.articleHHATags.hhaPortals);
    // temp_exp_PORTFOLIO-30998_start
    const newSmbAnalyticsSend = useSelector((state) => state.newSmbAnalyticsSend);
    useExperiment('exp_30998_smb_new2', newSmbAnalyticsSend, newSmbAnalyticsSend);
    // temp_exp_PORTFOLIO-30998_end
    return (
        <>
            <ContainerForXSL place="legacy-page-layout-xsl" />
            {showArticleAreaSwitcher && (
                <Portal place="promoAreaSwitcher">
                    <AreaSwitcherPromo />
                </Portal>
            )}
            {!!hhaPortals &&
                hhaPortals.map(({ placeName, tagXmlStr }) => (
                    <HHAPortal placeName={placeName} tagXmlStr={tagXmlStr} key={placeName} />
                ))}
        </>
    );
};

export default PromoArticle;
