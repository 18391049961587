import { domToReact } from 'html-react-parser';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';

import { TagMapperComponent, TagMapperProps } from 'src/hooks/useParseHtml';

const LinkMapper: TagMapperComponent<TagMapperProps> = ({ options, originalElement: { attribs, children } }) => {
    if (!attribs || !children) {
        return <></>;
    }

    return attribs.href?.startsWith('#') ? (
        <BlokoLink href={attribs.href} target={attribs.target} disableVisited>
            {domToReact(children, options)}
        </BlokoLink>
    ) : (
        <BlokoLink Element={SPALink} to={attribs.href} target={attribs.target} nonMagritteExternalLink disableVisited>
            {domToReact(children, options)}
        </BlokoLink>
    );
};

export default LinkMapper;
