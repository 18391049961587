import { FC, MouseEventHandler, useEffect, useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import {
    TelegramSolidSize16,
    TelegramBrandSize16,
    ViberSolidSize16,
    ViberBrandSize16,
    WhatsappSolidSize16,
    WhatsappBrandSize16,
    OkSolidSize16,
    VkBrandSize16,
    VkSolidSize16,
    FacebookBrandSize16,
    FacebookSolidSize16,
    TwitterBrandSize16,
    TwitterSolidSize16,
    OkBrandSize16,
} from '@hh.ru/magritte-ui/service';
import Share from 'bloko/blocks/share';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import { useSelector } from 'src/hooks/useSelector';
import { ShareButtonProps, SocialShareIcons } from 'src/models/articlePage';

import { services } from 'src/pages/Article/components/PageHeader/DefaultPageHeader/ShareBar/services';

const IconMap = {
    [SocialShareIcons.Vk]: { color: VkBrandSize16, default: VkSolidSize16 },
    [SocialShareIcons.Facebook]: {
        color: FacebookBrandSize16,
        default: FacebookSolidSize16,
    },
    [SocialShareIcons.Twitter]: {
        color: TwitterBrandSize16,
        default: TwitterSolidSize16,
    },
    [SocialShareIcons.Ok]: { color: OkBrandSize16, default: OkSolidSize16 },
    [SocialShareIcons.Whatsapp]: {
        color: WhatsappBrandSize16,
        default: WhatsappSolidSize16,
    },
    [SocialShareIcons.Viber]: { color: ViberBrandSize16, default: ViberSolidSize16 },
    [SocialShareIcons.Telegram]: {
        color: TelegramBrandSize16,
        default: TelegramSolidSize16,
    },
};

const POPUP = {
    width: 500,
    height: 400,
};

const ShareButton: FC<ShareButtonProps> = ({ icon, url, type }) => {
    const topLevelDomain = useSelector((state) => state.topLevelDomain);
    const showMainArticleImage = useSelector((state) => state.articlePage?.showMainArticleImage);

    const [counter, setCounter] = useState<number | null>(0);

    useEffect(() => {
        const location = topLevelDomain
            ? `${window.location.protocol}//${topLevelDomain}${window.location.pathname}`
            : '';

        if (services[type]) {
            services[type].injectScript(location, setCounter);
        }
    }, [topLevelDomain, type]);

    const onClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        event.preventDefault();
        window.open(
            url,
            'socialShare',
            `resizable=yes,top=${(screen.height - POPUP.height) / 2},left=${(screen.width - POPUP.width) / 2},height=${
                POPUP.height
            },width=${POPUP.width},noopener`
        );
        Analytics.trackPageView(`virtual/click/social/article/${type}`);
    };

    const IconComponent = IconMap[icon][!showMainArticleImage ? 'color' : 'default'];

    return (
        <Share
            Element={'a'}
            href={url}
            icon={
                <BlokoIconReplaceContainer>
                    <IconComponent initialColor={!showMainArticleImage ? 'primary' : 'contrast'} />
                </BlokoIconReplaceContainer>
            }
            onClick={onClick}
            count={counter && counter > 0 ? counter : undefined}
            inverted={showMainArticleImage}
        />
    );
};

export default ShareButton;
