import { VSpacing } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { InterviewLinkData } from 'src/models/insiderArticleSpecificData';

import styles from './other-interviews.less';

interface OtherInterviewProps {
    interview: InterviewLinkData;
    employerId: number;
}

const OtherInterview: TranslatedComponent<OtherInterviewProps> = ({ interview, employerId }) => {
    return (
        <Column xs="4" s="2" m="2" l="2">
            <div className={styles.mobileBorderBottom}>
                <VSpacing default={0} xs={4} />
                <BlokoLink
                    Element={SPALink}
                    to={`/interview/${interview.code}?employerId=${employerId}`}
                    kind={LinkKind.Tertiary}
                >
                    <div className={styles.interviewLayout}>
                        <img
                            loading="lazy"
                            className={styles.interviewImage}
                            src={interview.previewImg}
                            alt={interview.title}
                        />
                        <Text size={TextSize.Small}>{interview.title}</Text>
                    </div>
                </BlokoLink>
                <VSpacing default={0} xs={4} />
            </div>
        </Column>
    );
};

export default translation(OtherInterview);
